import SmoothCollapse from 'react-smooth-collapse';
import styled from 'styled-components';
import { FlexRow, InputErrorMessage, NumberInput } from '../../../elements';

export const SubQuestionCont = styled.div<{ isActive?: boolean; addSpace?: boolean; direction: string }>`
  margin-bottom: ${({ addSpace }) => (addSpace ? '22px' : 0)};
  opacity: ${({ isActive = true }) => (!isActive ? 0.05 : 1)};
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-content: space-between;
`;

export const StyledInputErrorMessage = styled(InputErrorMessage)`
  margin: 8px 0 0;
  text-align: right;
`;

export const CenteredLoadingCont = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
`;

export const InvalidMessage = styled.p`
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.pageComponents.addOns.invalidMessage.textColor};
  font-weight: 600;
  width: 70%;
  margin: 8px 0;
`;

export const InvalidMessageContactNum = styled(InvalidMessage)`
  font-size: 16px;
  line-height: 21px;
  margin: 0 0 8px;
`;

export const AddOnQuestionCont = styled.div<{ isSelected: boolean; disabled: boolean }>`
  margin: 0 0 16px;
  border-radius: 4px;
  border: ${({ theme, isSelected, disabled }) =>
    isSelected && !disabled ? theme.formElements.visualSwitchbox.borderOnActive : '1px solid transparent'};
  background-color: ${({ theme, isSelected, disabled }) =>
    isSelected
      ? theme.formElements.visualSwitchbox.backgroundOnActive
      : !disabled
      ? theme.formElements.visualSwitchbox.background
      : '#EBEDF2'};
  padding: 16px;
  min-height: 72px;
`;

export const AddOnQuestionDesc = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  width: 85%;
  color: #4A4A4D;
`;

export const QuestionRow = styled(FlexRow)`
  justify-content: space-between;
`;

export const UnderWritingNumberInput = styled(NumberInput)`
  margin: 0;
`;

export const StyledSmoothCollapse = styled(SmoothCollapse)`
  margin-top: 16px;
  padding: 18px 16px;
  background-color: ${({ theme }) => theme.formElements.visualSwitchbox.expandedSection.background};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.formElements.visualSwitchbox.expandedSection.border};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  position: relative;
`;

export const UnderwritingQuestionCont = styled(FlexRow)`
  justify-content: space-between;
`;
