import axios from 'axios';
import { getRequestContext, reportToSentry } from './helpers/sentry';
import { api_versions, COTERIE_PARTNER_ID } from './constants';
import applicationDataTransformer from './helpers/applicationDataTransformer';
import getBaseUrl from './helpers/getBaseUrl';
import addPolicyDefaults from './helpers/addPolicyDefaults';
import { pipe } from './helpers/functionalHelpers';
import saveForLaterLink from './helpers/saveForLaterLink';

const api_types = {
  COTERIE_API: 'coterieAPI',
  PORTAL_API: 'portalAPI',
};

const defaultOptions = {
  version: api_versions.V1,
  useDefaultTokens: false,
};

const createAPIInstance = (api = api_types.COTERIE_API, options = defaultOptions) => {
  const { version, useDefaultTokens } = options;
  const axios_instance = axios.create({
    baseURL: getBaseUrl(version)[api],
    headers: {},
  });
  const persistedTokenData = localStorage.getItem('tokenData');
  axios_instance.defaults.headers.common['Authorization'] =
    persistedTokenData && !useDefaultTokens ? JSON.parse(persistedTokenData)[version] : `Token ${COTERIE_PARTNER_ID}`;

  return axios_instance;
};

const coterieAPI_v1 = createAPIInstance();
const coterieAPI_v1_4 = createAPIInstance(api_types.COTERIE_API, { version: api_versions.V1_4 });
const coterieAPI_v1_4_with_default_tokens = createAPIInstance(api_types.COTERIE_API, {
  version: api_versions.V1_4,
  useDefaultTokens: true,
});
const portalAPI = createAPIInstance(api_types.PORTAL_API);

export const resetApiTokens = () => {
  localStorage.removeItem('tokenData');
  setApiToken(COTERIE_PARTNER_ID);
};

export const setApiToken = (token) => {
  const tokenString = `Token ${token}`;
  const tokenData = {
    [api_versions.V1]: tokenString,
    [api_versions.V1_4]: tokenString,
  };
  localStorage.setItem('tokenData', JSON.stringify(tokenData));
  coterieAPI_v1.defaults.headers.common['Authorization'] = tokenString;
  coterieAPI_v1_4.defaults.headers.common['Authorization'] = tokenString;
};

export const setApiTokenForVersion = (version, token) => {
  const tokenString = `Token ${token}`;
  const persistedTokenData = localStorage.getItem('tokenData');
  const tokenData = persistedTokenData ? JSON.parse(persistedTokenData) : {};
  const newTokenData = {
    ...tokenData,
    [version]: tokenString,
  };
  localStorage.setItem('tokenData', JSON.stringify(newTokenData));

  switch (version) {
    case api_versions.V1:
      coterieAPI_v1.defaults.headers.common['Authorization'] = tokenString;
      break;
    case api_versions.V1_4:
      coterieAPI_v1_4.defaults.headers.common['Authorization'] = tokenString;
      break;
    default:
      return;
  }
};

export const getAuthHeader = (version = api_versions.V1) => {
  switch (version) {
    case api_versions.V1_4:
      return coterieAPI_v1_4.defaults.headers.common['Authorization'];
    case api_versions.V1:
    default:
      return coterieAPI_v1.defaults.headers.common['Authorization'];
  }
};

export const getIndustryName = (industryId) => {
  return coterieAPI_v1_4.get(`/industries/${industryId}`);
};

export const getIndustries = () => {
  return coterieAPI_v1_4.get('/industries');
};

export const getAkHashDescription = (akHash) => {
  return coterieAPI_v1_4.get(`/industries/akhash/${akHash}`);
};

// TODO:  Change these calls   to be like the RC1 call below, let the reducer handle the promise
export const failFastCheck = (data) => {
  const { applicationId } = data;
  delete data.applicationId;
  return coterieAPI_v1_4.post('/applications', { ...data, externalId: applicationId });
};

export const fetchExistingApplication = (applicationId) => coterieAPI_v1_4.get(`/applications/${applicationId}`);

export const rateCallOne = (data) => {
  delete data.endorsementIds;
  delete data.industryName;
  return coterieAPI_v1_4.post('/quotes', pipe(applicationDataTransformer, addPolicyDefaults)(data));
};

export const rateCallTwo = (data) =>
  coterieAPI_v1_4.post('/quotes/bindable', pipe(applicationDataTransformer, addPolicyDefaults)(data));

export const anonymousRateCall = (data) =>
  coterieAPI_v1_4_with_default_tokens.post(
    '/quotes/bindable',
    pipe(applicationDataTransformer, addPolicyDefaults)(data)
  );

export const bindQuote = (data) => {
  const addAgencyExternalId = data.agencyExternalId ? `?agencyExternalId=${data.agencyExternalId}` : '';
  const addProducerOrAgencyExternalId = data.producerExternalId
    ? { producerExternalId: data.producerExternalId }
    : { agencyExternalId: data.agencyExternalId };

  if (data.useStripeCheckout) {
    return coterieAPI_v1_4.post(`/quotes/${data.quoteId}/bind${addAgencyExternalId}`, {
      TokenizedPaymentId: data.tokenId,
      PaymentInterval: data.paymentInterval || 'None',
      ...addProducerOrAgencyExternalId,
    });
  } else {
    const multiplePolicies = data.carrierAuthorizeNetPaymentNonces
      ? { carrierAuthorizeNetPaymentNonces: data.carrierAuthorizeNetPaymentNonces }
      : {};

    return coterieAPI_v1_4.post(`/quotes/${data.quoteId}/bind${addAgencyExternalId}`, {
      authorizeNetPaymentNonce: data.authorizeNetPaymentNonce,
      paymentInterval: data.paymentInterval || 'None',
      ...addProducerOrAgencyExternalId,
      ...multiplePolicies,
    });
  }
};

export const updateApplicationOnServer = (data) =>
  coterieAPI_v1_4.post('/applications', pipe(applicationDataTransformer, addPolicyDefaults)(data));

export const fetchAcknowledgements = (industryId) => coterieAPI_v1_4.get(`acknowledgements/${industryId}`);

export const fetchPartnerInfo = (publicToken) => coterieAPI_v1_4.get(`/partners/${publicToken}`);

export const fetchProducerInfoFromEMail = (email) => coterieAPI_v1.get(`/validation/producer?email=${email}`);

export const typeAhead = (address) => {
  return coterieAPI_v1_4
    .get(`address/typeahead/${address}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err));
};

export const verifyAddressStateAndZip = (state, zipcode) => {
  return coterieAPI_v1
    .get(`address/verifyStateZip/${state}${zipcode}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err));
};

export const verifyAddress = (address) => {
  const secondaryAddress = address.secondary ? ` ${address.secondary}` : '';
  const addressParams = `street=${address.street}${secondaryAddress}&city=${address.city}&state=${address.state}&zip=${address.zipCode}`;
  return coterieAPI_v1_4
    .get(`quoteflow/address/verify?${addressParams}`)
    .then((res) => res.data.data)
    .catch((err) => Promise.reject(err));
};

export const fetchTypeAheadEntries = (address) => {
  const requestURL = `address/entries`;
  const queryParams = `street=${encodeURIComponent(address.street)}&secondary=${encodeURIComponent(
    address.secondary
  )}&entries=${encodeURIComponent(address.entries)}&city=${encodeURIComponent(address.city)}&state=${encodeURIComponent(
    address.state
  )}&zipCode=${encodeURIComponent(address.zipCode)}`;

  return coterieAPI_v1_4
    .get(`${requestURL}?${queryParams}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err));
};

export const emailApplicationLink = ({
  email,
  applicationUrl,
  contactFirstName,
  quote,
  agencyExternalId,
  channelPartnerGuid,
}) => {
  const formattedLink = saveForLaterLink(applicationUrl, agencyExternalId, channelPartnerGuid);
  const requestData = {
    email,
    applicationUrl: agencyExternalId ? formattedLink : applicationUrl,
    ...(contactFirstName ? { contactFirstName } : {}),
    ...(quote ? { quote } : {}),
  };

  return axios
    .post('https://coteriefunctions.azurewebsites.net/api/EmailSavedApplication', requestData)
    .then((res) => res.data)
    .catch((err) => reportToSentry(err, getRequestContext({ requestData })));
};

export const fetchAvailableEndorsements = (application) =>
  coterieAPI_v1_4.post('/applications/availableEndorsements', application);

export const verifyMatchingAgencyChannelPartner = (agencyGUID, channelPartnerGUID) =>
  portalAPI.get(`/validation/${agencyGUID}/${channelPartnerGUID}`);

export const validateEmail = (email) => coterieAPI_v1_4.get(`validation/${email}/validate`);
