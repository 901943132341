import { Dictionary } from 'lodash';
import {
  setLiquorLiabilityCoverage,
  setRestaurantCoverage,
  setEquipmentBreakdownCoverage,
  setHiredAndNonOwnedAuto,
} from '../../actions/application';
import { formatCurrency } from '../../helpers/formatNumber';

type InputBoundaries = Dictionary<number>;

export type EndorsementUnderwritingQuestions = {
  [key: string]: {
    QUESTION_COPY: string;
    ERROR_COPY: string;
    validateInput: (value: number, boundaries: InputBoundaries) => boolean;
  };
};

export type EndorsementValidationQuestion = {
  QUESTION_COPY: string;
};

export type EndorsementValidation = {
  validationQuestions: EndorsementValidationQuestion[];
  validationFunc: (...args: any[]) => boolean;
};

export interface EndorsementSubQuestions {
  underwritingQuestions?: EndorsementUnderwritingQuestions;
  validation?: EndorsementValidation;
}

interface EndorsementObj {
  LABEL: string;
  QUESTION_COPY: string;
  action: (value: any) => Action;
  subQuestions?: EndorsementSubQuestions;
  coverageInfo: (data: Dictionary<number>) => CoverageInfo[];
}

export type CoverageInfo = {
  coverage: string;
  limit: string;
};

export const endorsementsMap: Dictionary<EndorsementObj> = {
  liquorLiabilityEndorsement: {
    LABEL: 'Liquor Liability Coverage',
    QUESTION_COPY: 'Add this coverage if you sell, serve or furnish alcoholic beverages.',
    action: setLiquorLiabilityCoverage,
    subQuestions: {
      underwritingQuestions: {
        alcoholicBeverageSales: {
          QUESTION_COPY: 'What are your annual liquor sales?',
          ERROR_COPY: 'Annual liquor sales cannot exceed gross annual sales',
          validateInput: (value, { grossAnnualSales: top }) => top >= value,
        },
      },
    },
    coverageInfo: ({ glLimit, glAggregateLimit }) => [
      { coverage: 'Per Claim Limit', limit: formatCurrency(glLimit) },
      { coverage: 'Yearly Limit', limit: formatCurrency(glAggregateLimit) },
    ],
  },
  equipmentBreakdownEndorsement: {
    LABEL: 'Equipment Breakdown Coverage',
    QUESTION_COPY:
      'Add this coverage to protect your business equipment from unexpected events like power surges or outages.',
    action: setEquipmentBreakdownCoverage,
    coverageInfo: ({ buildingLimit, bppLimit }) => [
      {
        coverage: 'Equipment Breakdown Limit',
        limit: formatCurrency(Boolean(buildingLimit) ? buildingLimit : bppLimit),
      },
    ],
  },
  restaurantCoverage: {
    LABEL: 'Restaurant Coverage',
    QUESTION_COPY:
      'Add this coverage to protect your restaurant against things like food spoilage and kitchen accidents. ',
    action: setRestaurantCoverage,
    coverageInfo: ({ glLimit }) => [
      { coverage: 'Food Contamination', limit: '$10,000' },
      { coverage: 'Additional Advertising Expenses', limit: '$3,000' },
      { coverage: 'Spoilage', limit: '$10,000' },
      { coverage: 'Reward Payment', limit: '$5,000' },
      { coverage: 'Brands and Labels', limit: formatCurrency(glLimit) },
      { coverage: 'Ordinance and Law', limit: formatCurrency(glLimit) },
      { coverage: 'Lock Replacement', limit: '$1,000' },
      { coverage: 'Delivery Errors and Omissions', limit: '$10,000' },
      { coverage: 'Merchandise withdrawal Expense', limit: '$25,000' },
    ],
  },
  hiredAutoAndNonOwnedAuto: {
    LABEL: 'Hired and Non-Owned Auto Coverage',
    QUESTION_COPY: 'Add this coverage if your business uses personal autos for work-related reasons.',
    action: setHiredAndNonOwnedAuto,
    subQuestions: {
      validation: {
        validationFunc: (answers) => !Object.values(answers).includes(true),
        validationQuestions: [
          {
            QUESTION_COPY: 'Does your business own any autos?',
          },
          {
            QUESTION_COPY: 'Does your business ever use autos to transport passengers?',
          },
          {
            QUESTION_COPY: 'Does your business ever operate your customer’s autos?',
          },
        ],
      },
    },
    coverageInfo: ({ glLimit, glAggregateLimit }) => [
      { coverage: 'Per Claim Limit', limit: formatCurrency(glLimit) },
      { coverage: 'Yearly Limit', limit: formatCurrency(glAggregateLimit) },
    ],
  },
};
