import React, { useEffect, useState } from 'react';
import { debounce, Dictionary } from 'lodash';
import { LabelLarge } from '../../elements';
import { EndorsementUnderwritingQuestions } from './addOnQuestionMap';
import {
  SubQuestionCont,
  UnderwritingQuestionCont,
  UnderWritingNumberInput,
  StyledInputErrorMessage,
} from './styles/addons';
import CenteredLoadingSpinner from '../AddOns/shared/CenteredLoadingSpinner';
import { NumberFormatValues } from 'react-number-format';

interface UnderWritingQuestionsProps {
  underwritingQuestions: EndorsementUnderwritingQuestions;
  value: Dictionary<number>;
  validationData: Dictionary<any>;
  action: (value: any) => void;
  isLoading: boolean;
  setExpanded: (value: boolean) => void;
}

const UnderWritingQuestions = ({
  underwritingQuestions,
  value,
  validationData,
  action,
  isLoading,
  setExpanded,
}: UnderWritingQuestionsProps) => {
  const [invalidInputs, setInvalidInputs] = useState<{ [key: string]: boolean }>({});
  const [isSimulatedLoading, setIsSimulatedLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [inputKey, setInputKey] = useState(0);

  useEffect(() => {
    setInputKey((i) => i + 1);
  }, [value]);

  useEffect(() => {
    const hasInvalidInput = Object.keys(invalidInputs).filter((input) => invalidInputs[input]).length === 0;
    const areAllSubQuestionsAnswered =
      value != null &&
      Object.keys(value).length &&
      Object.keys(value).reduce((accum, subQuestionValue) => {
        if (!accum) return false;
        return subQuestionValue != null;
      }, true);
    if (areAllSubQuestionsAnswered && hasInvalidInput) {
      setHasLoaded(false);
      setIsSimulatedLoading(true);
      const simulatedLoading = setTimeout(() => {
        setExpanded(false);
      }, 3000);
      const simulatedOnSuccess = setTimeout(() => {
        setHasLoaded(true);
      }, Math.random() * 1000 + 1000);

      return () => {
        clearTimeout(simulatedLoading);
        clearTimeout(simulatedOnSuccess);
        setIsSimulatedLoading(false);
        setHasLoaded(false);
      };
    }
  }, [value, invalidInputs, setExpanded]);

  return (
    <>
      {isSimulatedLoading && <CenteredLoadingSpinner showCheckMark={hasLoaded} />}
      {Object.keys(underwritingQuestions).map((question, index) => {
        const { QUESTION_COPY, ERROR_COPY, validateInput } = underwritingQuestions[question];
        const isValid = value?.[question] != null ? validateInput(value[question], validationData) : true;
        const isLastQuestion = index === Object.keys(underwritingQuestions).length - 1;

        if (!isValid && !invalidInputs[question]) {
          setInvalidInputs({ ...invalidInputs, [question]: true });
        } else if (isValid && invalidInputs[question]) {
          setInvalidInputs({ ...invalidInputs, [question]: false });
        }

        const updateEndorsementValue = (value: number | undefined | null) => {
          const valueData = value || {};
          const newEndorsementValue = { ...valueData, [question]: value };
          action(newEndorsementValue);
        };

        const handleOnChange = debounce((event: NumberFormatValues) => {
          updateEndorsementValue(event.floatValue || null);
        }, 2000);

        return (
          <SubQuestionCont key={question} isActive={!isSimulatedLoading} direction="column" addSpace={!isLastQuestion}>
            <UnderwritingQuestionCont>
              <LabelLarge htmlFor={question}>{QUESTION_COPY}</LabelLarge>
              <UnderWritingNumberInput
                name={question}
                key={inputKey}
                value={value[question]}
                onValueChange={handleOnChange}
                customWidth="quarter"
                marginBottom="none"
                thousandSeparator={true}
                prefix="$"
                aria-label={QUESTION_COPY}
                data-cy={question}
                disabled={isLoading}
                hasError={!isValid}
                placeholder="e.g., $150,000"
              />
            </UnderwritingQuestionCont>
            {!isValid && <StyledInputErrorMessage>{ERROR_COPY}</StyledInputErrorMessage>}
          </SubQuestionCont>
        );
      })}
    </>
  );
};

export default UnderWritingQuestions;
