import React from 'react';
import { useThemeContext } from '../../../elements/theme/CustomThemeProvider';
import LoadingSpinner from '../../Shared/LoadingSpinnerDots';
import { CenteredLoadingCont } from '../styles/addons';

interface CenteredLoadingSpinnerCompProps {
  useAltColor?: boolean;
  showCheckMark?: boolean;
}

const CenteredLoadingSpinner = ({ useAltColor = false, showCheckMark }: CenteredLoadingSpinnerCompProps) => {
  const { theme } = useThemeContext();
  return (
    <CenteredLoadingCont data-testid="loading-spinner">
      {showCheckMark ? (
        <img src={theme.icons.checkMark} alt="checkmark" height="42px" />
      ) : (
        <LoadingSpinner useAltColor={useAltColor} />
      )}
    </CenteredLoadingCont>
  );
};

export default CenteredLoadingSpinner;
