import React, { useEffect, useState, FunctionComponent } from 'react';
import { getAkHashDescription } from '../api';
import styled from 'styled-components';
import { Dictionary } from 'lodash';
import RadioBtn from '../elements/RadioBtn';
import { akHashMap } from '@coterieinsure/ux-components';

interface PropTypes {
  selectedIndustries: string[];
  selectIndustry: (industryId: number, industryName: string, industryHash: string) => void;
  selectedId: string;
}

interface IndustrySearchResult {
  id: number;
  name: string;
  hash: string;
}

const IndustrySelect: FunctionComponent<PropTypes> = ({ selectedIndustries = [], selectIndustry, selectedId }) => {
  const [industries, updateIndustries] = useState<IndustrySearchResult[]>([]);

  useEffect(() => {
    (async () => {
      const industryNames: IndustrySearchResult[] = await Promise.all(
        selectedIndustries.map(async (id): Promise<IndustrySearchResult> => {
          const result = await getAkHashDescription(id);
          const map: Dictionary<string> = akHashMap;

          return {
            id: parseInt(map[id]),
            name: result.data,
            hash: id,
          };
        })
      );

      updateIndustries(industryNames);
    })();
  }, [updateIndustries, selectedIndustries]);

  return (
    <IndustrySelectContainer data-cy="industry-select">
      {industries &&
        industries.map((industry: IndustrySearchResult) => (
          <RadioBtn
            key={industry.id}
            id={industry.id}
            labelText={industry.name}
            isChecked={industry.id.toString() === selectedId}
            onChange={() => selectIndustry(industry.id, industry.name, industry.hash)}
          />
        ))}
    </IndustrySelectContainer>
  );
};

const IndustrySelectContainer = styled.ul`
  margin: 32px 0 0;
  padding: 0;
`;

export default IndustrySelect;
