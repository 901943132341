export const DROPIN = 'dropin';
export const QUOTEFLOW = 'quoteflow';
export const MDA = 'mda';

interface AddSource {
  isDropin: boolean;
  isMDA?: boolean;
}

export const addSourceValue = ({ isDropin, isMDA }: AddSource) => {
  if (isMDA) {
    return MDA;
  } else if (isDropin) {
    return DROPIN;
  } else {
    return QUOTEFLOW;
  }
};

export const addSource = (sources: AddSource) => ({
  source: addSourceValue(sources),
  sourceVersion: process.env.REACT_APP_GIT_SHA,
});
