import edit_icon from '../../icons/edit_icon_blue.svg';
import trash_can from '../../icons/trash_can_blue.svg';
import check_mark from '../../icons/checkmark_green.svg';
import caret from '../../icons/caret_blue.svg';
import calendar from '../../icons/calendar_blue.svg';
import phone from '../../icons/phone_blue.svg';

const colors = {
  primary: {
    // Blue
    zero: '#4223FF',
    fifty: '#A091FF',
    ten: '#ECE9FF',
  },
  secondary: {
    // Green
    zero: '#40FFB1',
    darkTen: '#3AE69F',
    eighty: '#A0FFD8',
    twentyFive: '#CFFFEB',
  },
  tertiary: {
    // Navy
    zero: '#0D0D46',
    eighty: '#3E3E6C',
    sixty: '#6D6D8F',
  },
  cyber: {
    // Purple
    zero: '#8527FF',
    fifty: '#C394FF',
    twenty: '#E6D3FF',
    ten: '#F3E9FF',
  },
  pl: {
    // Orange
    zero: '#FFAF45',
    fifty: '#FFD7A2',
    twentyFive: '#FFEBD0',
    ten: '#FFF7EC',
  },
  bop: {
    // Yellow
    zero: '#FFDB43',
    sixty: '#FFEA8F',
    thirty: '#FFF4C6',
    ten: '#FFFCED',
  },
  wc: {
    // Light Blue
    zero: '#40B6FF',
    sixty: '#8DD4FF',
    thirty: '#C5E9FF',
    ten: '#EDF8FF',
  },
  alert: {
    // Red
    zero: '#FF4874',
    fifty: '#FFA2B8',
    twenty: '#FFDAE3',
    ten: '#FFECF1',
    dark: '#CC3A5D',
  },
  neutral: {
    // Gray scale
    dark: '#4A4A4D',
    medium: '#626266',
    regular: '#B8B8BC',
    light: '#EBEDF2',
    special: '#F2F5FA',
    black: '#000000',
    white: '#FFFFFF',
  },
  other: {
    orange: '#8F6224',
  },
};

const typefaces = {
  gilroy: 'Gilroy',
  questrial: 'Questrial',
};

const fontColors = {
  primary: colors.tertiary.zero,
  secondary: colors.tertiary.sixty,
  tertiary: colors.primary.zero,
  quaternary: colors.neutral.dark,
  darkThemePrimary: colors.neutral.white,
  subdued: colors.neutral.regular,
  subduedDark: colors.neutral.medium,
  error: colors.alert.zero,
  link: colors.primary.zero,
  warning: colors.other.orange,
};

const fontSizes = {
  extraLarge: '30px',
  large: '24px',
  medium: '20px',
  small: '16px',
  extraSmall: '13px',
  labelLarge: '16px',
  labelSmall: '12px',
  labelError: '12px',
  bodyLarge: '18px',
  bodyRegular: '15px',
  bodySmall: '13px',
};

const font = {
  typeface: {
    primary: typefaces.gilroy,
    secondary: typefaces.questrial,
  },
  font: fontSizes,
};

const buttonSizes = {
  height: {
    large: '46px', // subtracted 2px from style-guide to account for border
    small: '30px',
  },
  width: {
    max: '232px',
    min: '120px',
    small: {
      max: '132px',
      min: '80px',
    },
  },
};

const buttons = {
  solid: {
    textColor: colors.tertiary.zero,
    textColorOnHover: colors.neutral.white,
    fontSize: fontSizes.bodyRegular,
    fontSizeSmall: fontSizes.extraSmall,
    background: colors.secondary.zero,
    backgroundOnHover: colors.primary.zero,
    borderOnFocus: `1px dashed ${colors.primary.zero}`,
    darkTheme: {
      borderOnFocus: `1px dashed ${colors.neutral.white}`,
    },
    ...buttonSizes,
  },
  ghost: {
    textColor: colors.tertiary.zero,
    background: colors.neutral.white,
    backgroundOnHover: colors.primary.zero,
    border: `1px solid ${colors.neutral.regular}`,
    borderOnFocus: colors.primary.zero,
    darkTheme: {
      textColor: colors.neutral.white,
      textColorOnDisabled: colors.neutral.regular,
      background: colors.tertiary.zero,
      backgroundOnHover: colors.primary.zero,
      border: `1px solid ${colors.neutral.regular}`,
      borderOnFocus: colors.secondary.zero,
    },
    ...buttonSizes,
  },
  disabled: {
    textColor: '#7c7d98', // TODO replace with specific tone or gradient property defined by Design
    background: colors.neutral.light,
    darkTheme: {
      textColor: colors.neutral.white,
      opacity: 0.55, // TODO replace textColor with specific hex value since opacity cannot be applied to text
      background: colors.tertiary.sixty,
    },
    ...buttonSizes,
  },
  warning: {
    textColor: colors.neutral.white,
    background: colors.alert.zero,
    backgroundOnFocus: colors.alert.dark,
    borderOnFocus: `2px dotted ${colors.alert.zero}`,
    ...buttonSizes,
  },
  textOnly: {
    textColor: colors.primary.zero,
    borderOnHover: `2px dotted ${colors.primary.zero}`,
    borderOnHoverAlt: `2px dotted ${colors.secondary.zero}`,
    fontSize: fontSizes.bodyRegular,
    darkTheme: {
      textColor: colors.secondary.zero,
      borderOnHover: `2px dotted ${colors.secondary.zero}`,
    },
  },
};

const formElements = {
  input: {
    textColor: fontColors.primary,
    placeholderTextColor: colors.neutral.regular,
    background: colors.neutral.white,
    backgroundDisabled: colors.neutral.regular,
    border: `1px solid ${colors.neutral.regular}`,
    outlineOnFocus: `1px solid ${colors.primary.zero}`,
    borderOnHover: colors.neutral.regular,
    borderOnError: `1px solid ${colors.alert.zero}`,
    boxShadowOnFocus: `0 0 0 1px ${colors.primary.zero}`,
    subTextColor: fontColors.primary,
    errorTextColor: colors.alert.zero,
    fontSize: fontSizes.bodyRegular,
    fontSizeSmall: fontSizes.bodySmall,
    height: '48px',
    heightSmall: '32px',
  },
  label: {
    textColor: colors.tertiary.zero,
    size: {
      large: {
        fontSize: fontSizes.labelLarge,
      },
      small: {
        fontSize: fontSizes.labelSmall,
      },
    },
  },
  dropdown: {
    background: colors.neutral.white,
    backgroundSelected: colors.secondary.zero,
    linkTextColor: fontColors.tertiary,
    dropdownIndicator: colors.primary.zero,
  },
  checkbox: {
    backgroundUnselected: colors.neutral.white,
    backgroundSelected: colors.primary.zero,
    borderUnselected: `1px solid ${colors.neutral.regular}`,
    borderSelected: `1px solid ${colors.primary.zero}`,
  },
  radioButton: {
    backgroundSelected: colors.primary.zero,
    backgroundUnselected: colors.neutral.white,
    backgroundOnHover: colors.neutral.regular,
    labelColor: colors.neutral.dark,
  },
  switch: {
    background: colors.neutral.special,
    backgroundAlt: colors.neutral.white,
    selected: colors.primary.zero,
    unselected: colors.neutral.regular,
    boxShadowOnActive: `0 0 2px 3px ${colors.primary.zero}`,
  },
  visualCheckbox: {
    background: colors.neutral.special,
    backgroundWhite: colors.neutral.white,
    backgroundActive: colors.primary.zero,
    border: colors.neutral.regular,
    borderOnActive: `1px solid ${colors.primary.zero}`,
    expandedSection: {
      background: colors.neutral.white,
    },
    textLinkColor: colors.primary.zero,
    vertical: {
      background: colors.neutral.special,
      borderOnActive: `1px solid ${colors.primary.zero}`,
      darkTheme: {
        background: colors.neutral.white,
        borderOnActive: `1px solid ${colors.primary.zero}`,
      },
    },
  },
  visualSwitchbox: {
    background: colors.neutral.special,
    backgroundOnActive: colors.cyber.ten,
    border: `1px solid ${colors.neutral.special}`,
    borderOnFocus: `1px solid ${colors.primary.zero}`,
    borderOnActive: `1px solid ${colors.primary.zero}`,
    expandedSection: {
      background: colors.neutral.white,
      border: colors.primary.zero,
    },
  },
  detailCards: {
    background: colors.neutral.special,
  },
};

const selectors = {
  multiSelect: {
    textColorUnselected: colors.tertiary.zero,
    textColorSelected: colors.neutral.white,
    backgroundUnselected: colors.neutral.white,
    backgroundSelected: colors.primary.zero,
    backgroundOnHover: colors.primary.zero,
    textColorOnHover: colors.neutral.white,
    borderUnselected: `1px solid ${colors.neutral.light}`,
  },
  dynamicSelectors: {
    textColorUnselected: colors.tertiary.zero,
    textColorSelected: colors.neutral.white,
    textColorOnHover: colors.neutral.white,
    backgroundUnselected: colors.neutral.special,
    backgroundSelected: colors.primary.zero,
    backgroundOnHover: colors.primary.zero,
    backButton: colors.primary.zero,
    textColorDisabled: colors.neutral.regular,
    backgroundDisabled: colors.neutral.light,
  },
  yesNoButton: {
    textColorUnselected: colors.tertiary.zero,
    textColorSelected: colors.neutral.white,
    fontSize: fontSizes.small,
    fontSizeSmall: fontSizes.extraSmall,
    backgroundUnselected: colors.neutral.special,
    backgroundSelected: colors.primary.zero,
    height: '48px',
    heightSmall: '32px',
    width: '70px',
    widthSmall: '48px',
  },
  datePicker: {
    background: colors.neutral.white,
    border: `1px solid ${colors.primary.zero}`,
    borderMobile: `1px solid ${colors.primary.zero}`,
    textColor: colors.tertiary.zero,
    textColorOnSelect: colors.primary.zero,
    backgroundOnHover: colors.neutral.light,
    selectionBackgroundOnActive: colors.primary.zero,
  },
};

const modals = {
  overlay: {
    background: `${colors.tertiary.zero}b3`,
    zIndex: 20,
  },
  titleBar: {
    background: colors.tertiary.zero,
    textColor: colors.neutral.white,
    height: '64px',
    fontSize: fontSizes.medium,
  },
  detailBar: {
    background: colors.neutral.special,
    height: '64px',
    fontSize: fontSizes.medium,
    prefilledApp: {
      background: colors.neutral.special,
    },
  },
  content: {
    background: colors.neutral.white,
  },
  buttonBar: {
    background: colors.neutral.white,
    borderTop: colors.neutral.special,
  },
  tabs: {
    background: colors.neutral.special,
  },
  pageIndicator: {
    current: {
      border: `1px solid ${colors.primary.zero}`,
      background: colors.primary.zero,
    },
    notCurrent: {
      background: colors.neutral.light,
    },
  },
};

const tables = {
  header: {
    textColor: fontColors.primary,
    background: colors.neutral.light,
    border: colors.tertiary.zero,
  },
  body: {
    borderBottom: colors.neutral.light,
    textColor: fontColors.primary,
  },
};

const icons = {
  toolTip: {
    textColor: fontColors.tertiary,
    textColorOnHover: fontColors.darkThemePrimary,
    background: colors.primary.ten,
    backgroundOnHover: colors.primary.zero,
    content: {
      border: `1px solid ${colors.primary.zero}`,
      borderRadius: '3px',
      fontColor: fontColors.primary,
      background: colors.primary.ten,
    },
  },
  editIcon: edit_icon,
  trashCan: trash_can,
  checkMark: check_mark,
  caret: caret,
  calendar: calendar,
  successCheckmark: {
    fillColor: colors.secondary.zero,
    strokeColor: colors.secondary.zero,
  },
  phone: phone,
};

const pageComponents = {
  globals: {
    background: colors.neutral.white,
    errorTextColor: colors.alert.zero,
    errorBackground: colors.alert.ten,
    errorBorder: colors.alert.zero,
    listBullet: colors.primary.zero,
    loadingSpinnerColor: colors.secondary.zero,
    loadingSpinnerAltColor: colors.primary.zero,
    outlineOnFocus: `1px solid ${colors.primary.zero}`,
    linkColor: colors.primary.zero,
  },
  products: {
    gl: {
      color: colors.secondary.zero,
      background: colors.secondary.twentyFive,
    },
    pl: {
      color: colors.pl.zero,
      background: colors.pl.ten,
    },
    bop: {
      color: colors.bop.zero,
      background: colors.bop.ten,
    },
  },
  elements: {
    containers: {
      border: colors.neutral.white,
      borderColor: `${colors.neutral.white} transparent ${colors.neutral.white} transparent`,
    },
    text: {
      paragraph: {
        textColor: fontColors.quaternary,
      },
      textSharedStyles: {
        textColor: fontColors.primary,
        textColorDarkTheme: fontColors.darkThemePrimary,
      },
      pageTitle: {
        textColor: fontColors.tertiary,
      },
    },
  },
  errorModal: {
    background: colors.alert.ten,
    textColor: colors.alert.zero,
    border: colors.alert.zero,
    textColorAlt: fontColors.primary,
    backgroundAlt: colors.neutral.special,
    invalidPolicy: {
      background: colors.neutral.special,
    },
  },
  businessInfo: {
    previousLosses: {
      addEditLossButton: {
        textColor: fontColors.tertiary,
      },
      previousLossTitle: {
        textColor: fontColors.primary,
      },
      lossAmount: {
        textColor: fontColors.primary,
      },
      lossContainer: {
        border: colors.primary.zero,
      },
      lossDescription: {
        textColor: fontColors.primary,
      },
      subTitle: {
        textColor: fontColors.primary,
        fontSize: fontSizes.small,
      },
      addClaimsTitle: {
        textColor: fontColors.primary,
      },
    },
  },
  applicationProgress: {
    container: {
      background: colors.neutral.special,
    },
    activeLink: {
      borderBottom: `1px dashed ${colors.primary.zero}`,
      textColor: fontColors.tertiary,
    },
    connector: {
      borderLeft: `1px dashed ${colors.neutral.regular}`,
      borderLeftOnActive: `1px solid ${colors.primary.zero}`,
    },
    circle: {
      border: `1px solid ${colors.neutral.regular}`,
      borderOnActive: `1px solid ${colors.primary.zero}`,
      backgroundOnActive: colors.secondary.zero,
    },
    styledLink: {
      textColor: fontColors.primary,
    },
    unavailLink: {
      textColor: fontColors.subdued,
    },
    finePrintLink: {
      textColor: fontColors.tertiary,
    },
    finePrintText: {
      textColor: fontColors.quaternary,
    },
  },
  addOns: {
    invalidMessage: {
      textColor: fontColors.error,
    },
  },
  additionalInsureds: {
    existingAddIns: {
      border: `1px solid ${colors.primary.zero}`,
    },
  },
  globalComponents: {
    footerContainer: {
      background: colors.tertiary.zero,
    },
  },
  shared: {
    yesNoQuestion: {
      questionText: {
        textColor: fontColors.primary,
      },
    },
    infoSideBar: {
      premiumHeader: {
        textColor: fontColors.primary,
      },
      priceText: {
        textColor: fontColors.subduedDark,
      },
      price: {
        textColor: fontColors.primary,
      },
      priceCont: {
        border: colors.primary.zero,
      },
      toggleButton: {
        colorSelected: colors.neutral.white,
        colorUnselected: colors.tertiary.sixty,
        backgroundSelected: colors.primary.zero,
        backgroundUnselected: colors.neutral.special,
      },
    },
    multiButton: {
      incrementButton: {
        background: colors.neutral.white,
        border: `1px solid ${colors.primary.zero}`,
      },
      clearText: {
        textColor: fontColors.tertiary,
      },
      value: {
        textColor: fontColors.primary,
      },
    },
    mobileHeader: {
      progressBar: {
        backgroundColor: colors.secondary.twentyFive,
        valueColor: colors.secondary.zero,
      },
      headerContainer: {
        background: colors.neutral.special,
      },
      quoteContainer: {
        h3: {
          textColor: fontColors.primary,
        },
      },
      textContainer: {
        p: {
          textColor: fontColors.subduedDark,
        },
      },
    },
    prefilledSummary: {
      row: {
        borderTop: `2px solid ${colors.neutral.special}`,
      },
      field: {
        textColor: fontColors.primary,
      },
      value: {
        textColor: fontColors.quaternary,
      },
      p: {
        textColor: fontColors.quaternary,
      },
    },
    sectionDivider: {
      border: `1px solid ${colors.neutral.special}`,
    },
    locations: {
      existingAddressButton: {
        border: `1px solid ${colors.neutral.white}`,
        borderOnActive: `1px solid ${colors.primary.zero}`,
        background: colors.neutral.white,
      },
    },
    locationInput: {
      statusMessage: {
        textColor: colors.secondary.zero,
      },
      addressSuggestion: {
        backgroundOnHover: colors.secondary.zero,
      },
    },
    addressInput: {
      questionTitle: {
        textColor: fontColors.primary,
        textColorOnActive: fontColors.tertiary,
      },
    },
  },
  policySelection: {
    text: {
      textColor: fontColors.quaternary,
    },
    bullet: {
      textColor: fontColors.quaternary,
    },
    policyTitle: {
      textColor: fontColors.primary,
    },
    policyDesc: {
      textColor: fontColors.quaternary,
    },
    learnMoreButton: {
      textColor: fontColors.tertiary,
      borderOnHover: `2px dotted ${colors.primary.zero}`,
    },
  },
  summary: {
    businessNameHeader: {
      textColor: fontColors.primary,
      fontFamily: font.typeface.primary,
    },
    policies: {
      policyTitle: {
        textColor: fontColors.primary,
      },
    },
    checkoutForm: {
      fraudLabel: {
        textColor: fontColors.primary,
      },
      darkLabel: {
        textColor: fontColors.quaternary,
      },
      link: {
        textColor: fontColors.tertiary,
      },
      sectionTitle: {
        textColor: fontColors.primary,
      },
      switch: {
        background: colors.neutral.special,
      },
    },
    addOns: {
      card: {
        textColor: fontColors.primary,
      },
    },
    addPolicy: {
      textColor: fontColors.primary,
    },
    plusSymbol: {
      textColor: fontColors.primary,
    },
    title: {
      textColor: fontColors.primary,
    },
    content: {
      textColor: fontColors.quaternary,
    },
    link: {
      textColor: fontColors.link,
    },
    packages: {
      title: {
        textColor: fontColors.primary,
      },
      description: {
        textColor: fontColors.quaternary,
      },
      coverages: {
        textColor: fontColors.tertiary,
      },
    },
    styles: {
      sectionHeader: {
        textColor: fontColors.primary,
      },
      property: {
        textColor: fontColors.primary,
      },
      mobilePropertyValue: {
        textColor: fontColors.subduedDark,
      },
      styledCaret: {
        textColor: fontColors.tertiary,
      },
      sectionDescription: {
        textColor: fontColors.primary,
      },
    },
    exclusions: {
      header: {
        fontFamily: font.typeface.primary,
        fontSize: fontSizes.small,
        color: fontColors.primary,
      },
      listItem: {
        fontFamily: font.typeface.secondary,
        fontSize: fontSizes.bodyRegular,
        color: colors.neutral.dark,
        liColor: fontColors.primary,
      },
      background: {
        default: colors.neutral.white,
      },
    },
  },
  payment: {
    overlay: colors.tertiary.zero,
  },
  quoteHelp: {
    title: {
      textColor: fontColors.primary,
    },
    centeredLabel: {
      textColor: fontColors.subdued,
      fontSize: fontSizes.bodyRegular,
    },
    phoneNumber: {
      textColor: fontColors.primary,
    },
    button: {
      textColor: fontColors.primary,
    },
  },
  validationWarning: {
    textColor: fontColors.warning,
    backgroundColor: colors.pl.ten,
    iconColor: colors.pl.zero,
  },
};

const theme = {
  colors,
  font,
  buttons,
  formElements,
  selectors,
  modals,
  tables,
  icons,
  pageComponents,
};

export default theme;
