import React from 'react';
import styled from 'styled-components';

interface RadioBtnProps {
  onChange: (value: any) => void;
  id: string | number;
  labelText: string;
  isChecked: boolean;
}

const RadioBtn = ({ onChange, id, labelText, isChecked }: RadioBtnProps) => (
  <Container>
    <CustomInput
      type="radio"
      id={id.toString()}
      name="industry-select"
      checked={isChecked}
      onChange={onChange}
      value={id}
    />
    <RadioButtonFill />
    <LabelText htmlFor={id.toString()}>{labelText}</LabelText>
  </Container>
);

export default RadioBtn;

const Container = styled.li`
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 0 16px 0;

  @media (max-width: 768px) {
    margin: 0 0 32px 0;
  }
`;

const LabelText = styled.label`
  font-size: 15px;
  line-height: 23px;
  color: ${({ theme }) => theme.formElements.radioButton.labelColor};
  font-family: ${({ theme }) => theme.font.typeface.secondary};
`;

const RadioButtonFill = styled.div`
  position: absolute;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${({ theme }) => theme.formElements.radioButton.backgroundUnselected};
  border: 1px solid ${({ theme }) => theme.formElements.radioButton.backgroundOnHover};
`;

const CustomInput = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonFill} {
    background: ${({ theme }) => theme.formElements.radioButton.backgroundOnHover};
    &::after {
      content: '\f111';
      font-family: 'FontAwesome';
      display: block;
      color: ${({ theme }) => theme.formElements.radioButton.backgroundUnselected};
      width: 13px;
      height: 13px;
      margin: 4px;
      top: 25%;
      left: 0;
      right: 0;
      position: absolute;
      text-align: center;
      transform: translateY(-50%);
    }
  }
  &:checked + ${Container} {
    background: ${({ theme }) => theme.formElements.radioButton.backgroundSelected};
    border: 2px solid ${({ theme }) => theme.formElements.radioButton.backgroundSelected};
  }
  &:checked + ${RadioButtonFill} {
    background: ${({ theme }) => theme.formElements.radioButton.backgroundSelected};
    border: 1px solid ${({ theme }) => theme.formElements.radioButton.backgroundSelected};
    &::after {
      content: '\f111';
      font-family: 'FontAwesome';
      display: block;
      color: ${({ theme }) => theme.formElements.radioButton.backgroundUnselected};
      width: 13px;
      height: 13px;
      margin: 4px;
      top: 25%;
      left: 0;
      right: 0;
      position: absolute;
      text-align: center;
      transform: translateY(-50%);
    }
  }
  &:focus + ${RadioButtonFill} {
    outline: 2px solid ${({ theme }) => theme.formElements.radioButton.backgroundSelected};
  }
`;
