import { Dictionary } from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Switch } from '../../../elements';
import {
  selectGLAggregateLimit,
  selectGLLimit,
  selectLocationBPPLimit,
  selectLocationBuildingLimit,
} from '../../../selectors/application';
import { CoverageInfo, endorsementsMap } from '../../AddOns/addOnQuestionMap';
import {
  Card,
  MainCont,
  LabelCont,
  MoreInfoCaretCont,
  MoreInfoCaret,
  SwitchCont,
  StyledSmoothCollapse,
  StyledTableHeader,
  StyledTableBody,
  InfoBody,
  LimitHeader,
  LimitCell,
} from './styles';
import { useThemeContext } from '../../../elements/theme/CustomThemeProvider';

interface AddOnSummaryCardProps {
  endorsement: string;
  value: Dictionary<any> | string | number;
  glLimit: number;
  glAggregateLimit: number;
  bppLimit: number;
  buildingLimit: number;
  dispatch: Dispatch;
}

const AddOnsSummaryCard = ({
  endorsement,
  value,
  dispatch,
  glLimit,
  glAggregateLimit,
  bppLimit,
  buildingLimit,
}: AddOnSummaryCardProps) => {
  const { theme } = useThemeContext();
  const [isChecked, setIsChecked] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const { LABEL, action, coverageInfo } = endorsementsMap[endorsement];
  const handleOnSwitch = () => {
    if (isChecked) {
      dispatch(action(null));
      setIsChecked(false);
      setIsExpanded(false);
    } else {
      setIsChecked(true);
      dispatch(action(value));
    }
  };

  return (
    <Card isChecked={isChecked}>
      <MainCont>
        <LabelCont>
          <MoreInfoCaretCont isChecked={isChecked} onClick={() => isChecked && setIsExpanded(!isExpanded)}>
            {isChecked && (
              <MoreInfoCaret
                aria-label={`${isExpanded ? 'hide' : 'show'} ${LABEL} info`}
                src={theme.icons.caret}
                alt="More Info Caret Icon"
                expanded={isExpanded}
              />
            )}
          </MoreInfoCaretCont>
          <h3>{LABEL}</h3>
        </LabelCont>
        <SwitchCont>
          <Switch
            onChange={handleOnSwitch}
            checked={isChecked}
            ariaLabel={`${isChecked ? 'remove' : 'add'} ${LABEL}`}
            options={{ backgroundColor: theme.formElements.switch.backgroundAlt }}
          />
        </SwitchCont>
      </MainCont>
      <StyledSmoothCollapse expanded={isExpanded}>
        <StyledTableHeader>
          <thead>
            <tr>
              <th id={`${LABEL}-coverages`}>Coverage</th>
              <LimitHeader id={`${LABEL}-limits`}>Limit</LimitHeader>
            </tr>
          </thead>
        </StyledTableHeader>
        <InfoBody>
          <StyledTableBody>
            <tbody>
              {coverageInfo({ glLimit, glAggregateLimit, bppLimit, buildingLimit }).map((info: CoverageInfo) => (
                <tr key={`${LABEL}-${info.coverage}`}>
                  <td headers={`${LABEL}-coverages`}>{info.coverage}</td>
                  <LimitCell headers={`${LABEL}-limits`}>{info.limit}</LimitCell>
                </tr>
              ))}
            </tbody>
          </StyledTableBody>
        </InfoBody>
      </StyledSmoothCollapse>
    </Card>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  glLimit: selectGLLimit(state) || 0,
  glAggregateLimit: selectGLAggregateLimit(state) || 0,
  bppLimit: selectLocationBPPLimit(state) || 0,
  buildingLimit: selectLocationBuildingLimit(state) || 0,
});

export default connect(mapStateToProps)(AddOnsSummaryCard);
