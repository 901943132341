import { createSelector } from 'reselect';
import { POLICY_ENUMS } from '../constants';
import { TypesWithoutBuildingLimit } from '../types/enums';
import {
  selectAkHash,
  selectApplication,
  selectLocationBPPLimit,
  selectLocationBuildingLimit,
  selectLocationBuildingType,
  selectLocationCity,
  selectLocationState,
  selectLocationStreet,
  selectLocationZip,
} from './application';
import { getAvailableEndorsements, getIsAvailableEndorsementsLoading } from './availableEndorsements';
import { selectFatalError } from './errors';
import { selectBOPRatingEngine } from './quote';

const selectors = [
  selectFatalError,
  selectAkHash,
  getAvailableEndorsements,
  getIsAvailableEndorsementsLoading,
  selectApplication,
  selectBOPRatingEngine,
  selectLocationBuildingType,
  selectLocationStreet,
  selectLocationCity,
  selectLocationState,
  selectLocationZip,
  selectLocationBPPLimit,
  selectLocationBuildingLimit,
];

export const getShouldFetchAvailableEndorsements = createSelector(
  selectors,
  (
    fatalError,
    akHash,
    availableEndorsements,
    isAvailableEndorsementsLoading,
    application,
    ratingEngineVersion,
    locationBuildingType,
    locationStreet,
    locationCity,
    locationState,
    locationZip,
    bppLimit,
    buildingLimit
  ) => {
    const hasFatalError = Boolean(fatalError);
    const hasAkHash = Boolean(akHash);
    const hasAvailableEndorsements = availableEndorsements !== undefined;
    const isLoading = Boolean(isAvailableEndorsementsLoading);
    const isGlSelected = Boolean(application.applicationTypes?.includes(POLICY_ENUMS.GL));
    const isBopSelected = Boolean(application.applicationTypes?.includes(POLICY_ENUMS.BOP));
    const isBop2RatingEngine = ratingEngineVersion === 'BOP 2.0';
    const isTypeWithoutBuildingLimit = locationBuildingType in TypesWithoutBuildingLimit;
    const isBuildingLimitValid = isTypeWithoutBuildingLimit || buildingLimit != null;
    const isLocationInfoComplete =
      Boolean(locationStreet) &&
      Boolean(locationCity) &&
      Boolean(locationState) &&
      Boolean(locationZip) &&
      bppLimit != null &&
      isBuildingLimitValid;
    const isValidBOPSelected = isBopSelected && isBop2RatingEngine && isLocationInfoComplete;
    const areGLorBOPSelected = isGlSelected || isValidBOPSelected;

    return !hasFatalError && hasAkHash && !hasAvailableEndorsements && !isLoading && areGLorBOPSelected;
  }
);
