import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  LabelLarge,
  Input,
  Form as FormElement,
  InputCont,
  InputBox,
  PrimaryButton,
  InputErrorMessage,
} from '../../elements';
import { addAdditionalInsured } from '../../actions/application';
import AddressInput from './AddressInput';
import { validateEmailRegex } from '../../helpers/inputValidation/validateEmail';
import { INPUT_ERROR_MSG } from '../../constants';
import { useMediaQuery } from 'react-responsive';

const StyledPrimaryButton = styled(PrimaryButton)`
  margin: 24px 0 0;
`;
interface PropTypes {
  addAdditionalInsured: (name: AdditionalInsured) => void;
}

const AdditionalInsuredsForm = ({ addAdditionalInsured }: PropTypes) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [addInsAddress, setAddInsAddress] = useState({ street: '', city: '', zip: '', state: '' });

  const handleEmailOnBlur = () => setIsEmailValid(validateEmailRegex(email));

  const submitAdditionalInsured = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const name = `${firstName} ${lastName}`;
    const { street, city, zip, state } = addInsAddress;
    addAdditionalInsured({ name, street, city, zip, state, email });
    setFirstName('');
    setLastName('');
    setEmail('');
    setAddInsAddress({ street: '', city: '', zip: '', state: '' });
  };

  const isMobileOrTablet = useMediaQuery({ maxWidth: 1024 });

  return (
    <>
      <Form>
        <InputCont>
          <InputBox>
            <LabelLarge>First name</LabelLarge>
            <Input
              width={isMobileOrTablet ? 'full' : 'half'}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              data-cy="add-ins-first-name"
              aria-label="First name of additional insured"
              marginBottom="smaller"
            />
          </InputBox>
          <InputBox>
            <LabelLarge>Last name</LabelLarge>
            <Input
              width={isMobileOrTablet ? 'full' : 'half'}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              data-cy="add-ins-last-name"
              aria-label="Last name of additional insured"
              marginBottom="smaller"
            />
          </InputBox>
        </InputCont>
        <LabelLarge>Email</LabelLarge>
        <StyledInput
          width="full"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={handleEmailOnBlur}
          hasError={!isEmailValid}
          data-cy="add-ins-email"
          aria-label="Email of additional insured"
          marginBottom="smaller"
        />
        <StyledInputErrorMessage>{!isEmailValid && INPUT_ERROR_MSG.INVALID_EMAIL}</StyledInputErrorMessage>
        <AddressInput addInsAddress={addInsAddress} setAddInsAddress={setAddInsAddress} />
        <StyledPrimaryButton
          type="button"
          onClick={submitAdditionalInsured}
          disabled={!Boolean(addInsAddress.street && firstName && lastName && validateEmailRegex(email))}
          data-cy="add-addl-ins"
          aria-label="Add additional insured"
          small
        >
          Add person
        </StyledPrimaryButton>
      </Form>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  addAdditionalInsured: (data: AdditionalInsured) => dispatch(addAdditionalInsured(data)),
});

const Form = styled(FormElement)`
  margin: 0;
  display: inherit;
`;

export default connect(null, mapDispatchToProps)(AdditionalInsuredsForm);

const StyledInput = styled(Input)`
  margin-bottom: 8px;
`;

const StyledInputErrorMessage = styled(InputErrorMessage)`
  height: 16px;
  margin: 0 0 24px;
`;
