import { ENVIRONMENT_URL, api_versions } from '../constants';

const https = 'https://';
const DOMAIN = process.env.REACT_APP_DOMAIN;
const PORTAL_API = `${https}portalapi`;
const API = `${https}api`;

const getBaseUrl = (version = api_versions.V1) => {
  if (process.env.REACT_APP_SANDBOX_API === 'true' && process.env.NODE_ENV === 'development') {
    return {
      coterieAPI: `${API}-sandbox.${DOMAIN}/${version}/commercial`,
      portalAPI: `${PORTAL_API}-sandbox.${DOMAIN}/v1/`,
    };
  }

  switch (window.location.hostname.toLowerCase()) {
    // Production
    case ENVIRONMENT_URL.PRODUCTION:
    case `coterieclient.azurewebsites.net`:
      return {
        coterieAPI: `${API}.${DOMAIN}/${version}/commercial`,
        portalAPI: `${PORTAL_API}.${DOMAIN}/v1/`,
      };

    // Sandbox
    case ENVIRONMENT_URL.SANDBOX:
    case `coterieclient-sandbox.azurewebsites.net`:
      return {
        coterieAPI: `${API}-sandbox.${DOMAIN}/${version}/commercial`,
        portalAPI: `${PORTAL_API}-sandbox.${DOMAIN}/v1/`,
      };

    // Test
    case ENVIRONMENT_URL.TEST:
    case `coterieclient-test.azurewebsites.net`:
      return {
        coterieAPI: `${API}-test.${DOMAIN}/${version}/commercial`,
        portalAPI: `${PORTAL_API}-test.${DOMAIN}/v1/`,
      };

    // Local client with local API, for running full stack locally.  Configure localapi hostname using hosts file
    case 'uselocalapi':
      return {
        coterieAPI: `https://localhost:5001/${version}/commercial`,
        portalAPI: `https://localhost:5001/v1/`,
      };

    // Dev1
    case ENVIRONMENT_URL.DEV_1:
      return {
        coterieAPI: `https://dev1-coterie-api-east-app-service.azurewebsites.net/${version}/commercial`,
        portalAPI: `https://dev1-portal-api-east-app-service.azurewebsites.net/v1/`,
      };

    // Dev2
    case ENVIRONMENT_URL.DEV_2:
      return {
        coterieAPI: `https://dev2-coterie-api-east-app-service.azurewebsites.net/${version}/commercial`,
        portalAPI: `https://dev2-portal-api-east-app-service.azurewebsites.net/v1/`,
      };

    // Dev3
    case ENVIRONMENT_URL.DEV_3:
      return {
        coterieAPI: `https://dev3-coterie-api-east-app-service.azurewebsites.net/${version}/commercial`,
        portalAPI: `https://dev3-portal-api-east-app-service.azurewebsites.net/v1/`,
      };

    // Dev1 Staging
    case ENVIRONMENT_URL.DEV_1_STAGING:
      return {
        coterieAPI: `https://dev1-coterie-api-east-app-service-staging.azurewebsites.net/${version}/commercial`,
        portalAPI: `https://dev1-portal-api-east-app-service-staging.azurewebsites.net/v1/`,
      };

    // Dev2 Staging
    case ENVIRONMENT_URL.DEV_2_STAGING:
      return {
        coterieAPI: `https://dev2-coterie-api-east-app-service-staging.azurewebsites.net/${version}/commercial`,
        portalAPI: `https://dev2-portal-api-east-app-service-staging.azurewebsites.net/v1/`,
      };

    // Dev3 Staging
    case ENVIRONMENT_URL.DEV_3_STAGING:
      return {
        coterieAPI: `https://dev3-coterie-api-east-app-service-staging.azurewebsites.net/${version}/commercial`,
        portalAPI: `https://dev3-portal-api-east-app-service-staging.azurewebsites.net/v1/`,
      };

    // Default (Test)
    default:
      return {
        coterieAPI: `${API}-test.${DOMAIN}/${version}/commercial`,
        portalAPI: `${PORTAL_API}-test.${DOMAIN}/v1/`,
      };
  }
};

export default getBaseUrl;
