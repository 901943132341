import React from 'react';
import styled from 'styled-components';

interface YesOrNoQuestionProps {
  question?: string;
  value?: any;
  disabled?: boolean;
  selectYes: () => void;
  selectNo: () => void;
  cyLabel: string;
  dataTestId?: string;
  small?: boolean;
}

const YesNoQuestion = ({
  question,
  value,
  disabled,
  selectYes,
  selectNo,
  cyLabel,
  dataTestId,
  small,
}: YesOrNoQuestionProps) => (
  <QuestionCont data-testid={dataTestId}>
    <TextCont>
      <QuestionText>{question}</QuestionText>
    </TextCont>
    <ButtonCont>
      <Button
        onClick={selectYes}
        selected={value === true}
        data-cy={`yes-${cyLabel}`}
        disabled={disabled}
        type="button"
        aria-label={`${question} Yes.`}
        aria-pressed={value === true}
        small={small}
      >
        Yes
      </Button>
      <Button
        onClick={selectNo}
        selected={value === false}
        data-cy={`no-${cyLabel}`}
        disabled={disabled}
        type="button"
        aria-label={`${question} No.`}
        aria-pressed={value === false}
        small={small}
      >
        No
      </Button>
    </ButtonCont>
  </QuestionCont>
);

const QuestionCont = styled.div`
  width: 100%;
  display: flex;
  align-content: flex-start;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 32px;
  :first-child {
    margin: 10px 0;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const TextCont = styled.div`
  flex: 5;
  height: 100%;

  @media (min-width: 767px) {
    margin-right: 8px;
  }
`;

const ButtonCont = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-content: center;

  @media (max-width: 767px) {
    margin-top: 16px;
  }
`;

const Button = styled.button<{ selected: boolean; small?: boolean }>`
  margin: 0;
  height: ${({ small, theme }) =>
    small ? theme.selectors.yesNoButton.heightSmall : theme.selectors.yesNoButton.height};
  width: ${({ small, theme }) => (small ? theme.selectors.yesNoButton.widthSmall : theme.selectors.yesNoButton.width)};
  color: ${({ selected, theme }) =>
    selected ? theme.selectors.yesNoButton.textColorSelected : theme.selectors.yesNoButton.textColorUnselected};
  font-family: ${({ theme }) => theme.font.typeface.primary};
  font-size: ${({ small, theme }) =>
    small ? theme.selectors.yesNoButton.fontSizeSmall : theme.selectors.yesNoButton.fontSize};
  background-color: ${({ selected, theme }) =>
    selected ? theme.selectors.yesNoButton.backgroundSelected : theme.selectors.yesNoButton.backgroundUnselected};
  font-weight: 600;
  border: none;

  :hover {
    cursor: pointer;
  }
  :first-child {
    border-radius: 24px 0 0 24px;
    margin-right: 2px;
  }
  :last-child {
    border-radius: 0 24px 24px 0;
  }
  :disabled {
    cursor: not-allowed;
  }
`;

const QuestionText = styled.label<{ display?: string }>`
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.typeface.primary};
  color: ${({ theme }) => theme.pageComponents.shared.yesNoQuestion.questionText.textColor};
  font-weight: 600;
  display: ${({ display }) => display || 'block'};
`;

export default YesNoQuestion;
