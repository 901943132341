import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { selectApplication, selectPolicyTypes } from '../selectors/application';
import { getQuoteAmount, selectBOPRatingEngine } from '../selectors/quote';
import { PAGES } from '../constants';
import Packages from '../Components/Summary/Packages';
import { Container, Paragraph, TextButton } from '../elements';
import { PolicyType } from '../types/enums';
import EditIcon from '../Components/Shared/EditIcon';
import PolicySummaryCards from '../Components/Summary/Policies';
import AddPolicy from '../Components/Summary/AddPolicy';
import isApplicationBindable from '../helpers/isApplicationBindable';
import { getCurrentPage } from '../selectors/routing';
import { MobileAndTablet } from '../helpers/responsive';
import InfoSidebar from '../Components/Shared/InfoSidebar';
import AddOnsSummary from '../Components/Summary/AddOns';
import AddedServices from '../Components/Summary/AddedServices';
import SmoothCollapse from 'react-smooth-collapse';
import { getAvailableEndorsements } from '../selectors/availableEndorsements';

interface PropTypes {
  application: Application;
  selectedPolicies: PolicyType[];
  ratingEngine: string | null;
  isBindable: boolean;
  quoteAmount: string;
  goToAddInsuredPage: () => void;
  hasAvailableEndorsements: boolean;
}

class Summary extends Component<PropTypes> {
  componentDidMount() {
    document.title = 'Coterie - My Policy Summary';
    window.scrollTo(0, 0);
  }

  render() {
    const {
      application,
      selectedPolicies,
      ratingEngine,
      isBindable,
      quoteAmount,
      goToAddInsuredPage,
      hasAvailableEndorsements,
    } = this.props;
    const {
      legalBusinessName,
      dba,
      mailingAddressStreet,
      mailingAddressCity,
      mailingAddressState,
      mailingAddressZip,
      contactFirstName,
      contactLastName,
      contactEmail,
      endorsements,
    } = application;
    const shouldShowPackages = selectedPolicies?.includes(PolicyType.BOP) && ratingEngine === 'BOP 2.0';
    const hasBOPorGL = selectedPolicies?.includes(PolicyType.BOP) || selectedPolicies?.includes(PolicyType.GL);

    return (
      <Container data-cy="summary-page">
        <InnerContainer>
          <BusinessNameHeader data-cy="summary-business-name">
            {legalBusinessName}
            <EditIcon ariaLabel="business name" redirectTo={PAGES.BUSINESS_INFORMATION} />
          </BusinessNameHeader>
          {dba && (
            <Paragraph>
              DBA <DBA>{dba}</DBA>
            </Paragraph>
          )}
          <Paragraph>{`${contactFirstName} ${contactLastName}`}</Paragraph>
          <Paragraph>{contactEmail}</Paragraph>
          <Paragraph>{`${mailingAddressStreet}. ${mailingAddressCity}, ${mailingAddressState} ${mailingAddressZip}`}</Paragraph>
          <AdditionalInsuredsBtn
            onClick={goToAddInsuredPage}
            data-cy="go-to-add-insureds"
            aria-label="Add additional insureds"
          >
            {endorsements?.additionalInsureds?.length
              ? `(${endorsements?.additionalInsureds.length}) Additional Insureds`
              : 'Add Additional Insured'}
          </AdditionalInsuredsBtn>
          <MobileAndTablet>
            <InfoSidebar />
          </MobileAndTablet>
          <PolicySummaryCards />
        </InnerContainer>
        <SmoothCollapse expanded={isBindable} allowOverflowWhenOpen>
          <AddPolicy currentQuoteAmount={quoteAmount} />
        </SmoothCollapse>
        {hasBOPorGL && <AddedServices />}
        <SmoothCollapse expanded={hasAvailableEndorsements || shouldShowPackages} allowOverflowWhenOpen>
          <AddOnsSummary />
          {shouldShowPackages ? <Packages /> : null}
        </SmoothCollapse>
      </Container>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  application: selectApplication(state),
  selectedPolicies: selectPolicyTypes(state),
  ratingEngine: selectBOPRatingEngine(state),
  isBindable: isApplicationBindable(selectApplication(state)) && getCurrentPage(state) !== PAGES.PAYMENT,
  quoteAmount: getQuoteAmount(state),
  hasAvailableEndorsements: Boolean(getAvailableEndorsements(state)),
});

const mapDispatchToProps = {
  goToAddInsuredPage: () => ({ type: PAGES.ADDITIONAL_INSUREDS }),
};

const BusinessNameHeader = styled.h2`
  color: ${({ theme }) => theme.pageComponents.summary.businessNameHeader.textColor};
  margin: 0 0 5px;
  font-size: 20px;
`;

const DBA = styled.span`
  color: ${({ theme }) => theme.pageComponents.summary.businessNameHeader.textColor};
  font-family: ${({ theme }) => theme.pageComponents.summary.businessNameHeader.fontFamily};
  font-size: 20px;
  font-weight: 600;
`;

const InnerContainer = styled.div`
  width: 100%;
  align-self: center;
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

const AdditionalInsuredsBtn = styled(TextButton)`
  margin: 0.5em 0 15px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
