import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Dictionary, isEmpty } from 'lodash';
import styled, { css } from 'styled-components';
import { selectUserHasInvalidatedAddOns, selectAkHash, selectGrossAnnualSales } from '../../selectors/application';
import { InputLabel } from '../../elements';
import { EndorsementSubQuestions } from './addOnQuestionMap';
import ValidationQuestions from './ValidationQuestions';
import {
  AddOnQuestionCont,
  AddOnQuestionDesc,
  InvalidMessage,
  InvalidMessageContactNum,
  QuestionRow,
  StyledSmoothCollapse,
} from './styles/addons';
import Switch from '../../elements/Switch';
import UnderWritingQuestions from './UnderwritingQuestions';
import { useThemeContext } from '../../elements/theme/CustomThemeProvider';
import { liquorClasses100 } from '../../constants';
import { useFeatureFlags } from '../../toggle_tools/featureFlagTools';

interface AddonQuestionProps {
  endorsement: string;
  label: string;
  description: string | JSX.Element;
  value: boolean | Dictionary<any> | undefined;
  action: (value: any) => Action;
  subQuestions?: EndorsementSubQuestions;
  isLoading: boolean;
  validationData: Dictionary<number>;
  disabled?: boolean;
  akHash?: string;
  grossAnnualSales?: number;
}

interface ConnectedAddonQuestionProps extends AddonQuestionProps {
  isAddonInvalid: boolean;
}

export const invalidMessageCopy =
  'Based on your answers, we’re unable to offer this coverage. For help, please call our customer service.';
const contactNumber = '(855) 566–1011';

const RenderInvalidMessage = () => (
  <>
    <InvalidMessage>{invalidMessageCopy}</InvalidMessage>
    <InvalidMessageContactNum>{contactNumber}</InvalidMessageContactNum>
  </>
);

export const AddOnQuestion = ({
  endorsement,
  label,
  description,
  value,
  action,
  subQuestions,
  isLoading,
  validationData,
  isAddonInvalid,
  disabled = false,
  akHash,
  grossAnnualSales,
}: ConnectedAddonQuestionProps) => {
  const { theme } = useThemeContext();
  const { underwritingQuestions, validation } = subQuestions || {};
  const initialExpandedValue = typeof value === 'boolean' || value == null ? false : isEmpty(value);
  const [isExpanded, setExpanded] = useState(initialExpandedValue);

  const { useNewLiquorLiabilityRules } = useFeatureFlags();
  const isLiquorException =
    useNewLiquorLiabilityRules &&
    endorsement === 'liquorLiabilityEndorsement' &&
    liquorClasses100.includes(akHash || '');

  const getYesOrNoValue = (stateValue: boolean | Dictionary<any> | undefined) => {
    if (subQuestions && !isLiquorException) {
      if (isExpanded) {
        return true;
      } else {
        return Boolean(stateValue);
      }
    } else {
      return Boolean(stateValue);
    }
  };

  const yesOrNoValue = getYesOrNoValue(value);
  const underwritingQuestionValue = typeof value === 'boolean' || value == null ? {} : value;
  type OnChange = (
    checked: boolean,
    event: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent, Event>
  ) => void;

  const handleOnChange: OnChange = (checked, event) => {
    event.preventDefault();
    if (checked) {
      if (!isLiquorException && (subQuestions?.underwritingQuestions || subQuestions?.validation)) {
        setExpanded(true);
        action({});
      } else if (isLiquorException) {
        action({ alcoholicBeverageSales: grossAnnualSales });
      } else {
        action(true);
      }
    } else {
      setExpanded(false);
      action(null);
    }
  };

  return (
    <AddOnQuestionCont
      key={endorsement}
      isSelected={isExpanded ? false : yesOrNoValue}
      disabled={disabled}
      data-testid={`${endorsement}-container`}
    >
      <>
        <QuestionRow>
          {/* TODO verify if name/htmlFor are working for this label */}
          <StyledInputLabel size="xl" htmlFor={label} disabled={disabled} data-cy={`${endorsement}-addOn`}>
            {label}
          </StyledInputLabel>
          {!isAddonInvalid && !disabled && (
            <Switch
              name={label}
              onChange={handleOnChange}
              checked={yesOrNoValue}
              ariaLabel={label}
              options={{ backgroundColor: theme.formElements.switch.backgroundAlt }}
              dataTestId="AddOn-question"
            />
          )}
        </QuestionRow>
        {isAddonInvalid ? <RenderInvalidMessage /> : <AddOnQuestionDesc>{description}</AddOnQuestionDesc>}
      </>
      {!isLiquorException && subQuestions && (
        <StyledSmoothCollapse heightTransition=".5s ease" expanded={isExpanded} allowOverflowWhenOpen={true}>
          {underwritingQuestions && (
            <UnderWritingQuestions
              underwritingQuestions={underwritingQuestions}
              value={underwritingQuestionValue}
              validationData={validationData}
              action={action}
              isLoading={isLoading}
              setExpanded={setExpanded}
            />
          )}
          {validation && (
            <ValidationQuestions
              validation={validation}
              endorsement={endorsement}
              action={action}
              setExpanded={setExpanded}
            />
          )}
        </StyledSmoothCollapse>
      )}
    </AddOnQuestionCont>
  );
};

const mapStateToProps = (state: ReduxState, props: AddonQuestionProps) => ({
  isAddonInvalid: Boolean(selectUserHasInvalidatedAddOns(state)?.includes(props.endorsement)),
  akHash: selectAkHash(state),
  grossAnnualSales: selectGrossAnnualSales(state),
});

const StyledInputLabel = styled(InputLabel)<{ disabled: boolean }>`
  ${(props) =>
    props.disabled &&
    css`
      color: #4a4a4d;
    `};
`;

export default connect(mapStateToProps)(AddOnQuestion);
