import { Dispatch } from 'redux';
import { fetchProducerInfoFromEMail, setApiToken } from '../api';
import { reportToSentry } from '../helpers/sentry';
import { setIsMDA } from './agent';
import { setAgentProducerExternalId } from './agent';
import { clearApplication, fatalError, setPartnerInfo } from './application';

const handleMDAInbound = async (dispatch: Dispatch, getState: () => ReduxState) => {
  try {
    const paramsString = getState().location.search as string;

    // if query param is not the correct format, return early and dont try to fetch producer info
    if (!paramsString?.includes('p=')) return;

    const email = paramsString.replace('p=', '');
    // encode email addresses so emails with special characters can be used and interpreted by the API correctly
    const encodedEmail = encodeURIComponent(email);

    const { data } = await fetchProducerInfoFromEMail(encodedEmail);

    dispatch(clearApplication());
    dispatch(setPartnerInfo(data));
    dispatch(setIsMDA());
    dispatch(setAgentProducerExternalId(data.producerExternalId));
    setApiToken(data.publishableKey);
  } catch (e) {
    reportToSentry(e as any);
    dispatch(fatalError(e));
  }
};

export default handleMDAInbound;
