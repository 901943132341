import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { formatCurrency } from '../../../helpers/formatNumber';
import ToolTipIcon from '.././ToolTipIcon';
import { Text } from '../../../helpers/toolTipContent';
import close_gray from '../../../icons/close_gray.svg';
import checkmark_blue from '../../../icons/checkmark_blue.svg';
import piggy_bank from '../../../icons/piggy_bank.svg';

interface PropTypes {
  showPriceDrawer: boolean;
  onClose: () => void;
  isMonthly: boolean;
  monthlyPremium: number;
  premium: number;
  policyFees: number;
  installmentFees: number;
  hasFirstMonthWaived: boolean;
  hasLossControl: boolean;
  lossControlFees: number;
  month1Owed: number;
  totalYearlyOwed: number;
  monthlyFees: number;
  monthlyOwed: number;
  hasBOPorGL: boolean;
}

const PriceDrawer = ({
  showPriceDrawer,
  onClose,
  isMonthly,
  monthlyPremium,
  premium,
  policyFees,
  installmentFees,
  hasFirstMonthWaived,
  hasLossControl,
  lossControlFees,
  month1Owed,
  totalYearlyOwed,
  monthlyFees,
  monthlyOwed,
  hasBOPorGL,
}: PropTypes) => {
  const hasPolicyFee = Boolean(policyFees);

  const PriceDetails = [
    { label: 'Premium', amount: isMonthly ? monthlyPremium : premium, isVisible: true },
    { label: 'Annual Policy Fee', amount: policyFees, isVisible: hasPolicyFee },
    { label: 'Installment Fee', amount: installmentFees, isVisible: !hasFirstMonthWaived && Boolean(installmentFees) },
    {
      label: 'Manage My Risk Program',
      amount: isMonthly ? lossControlFees : lossControlFees * 12,
      isVisible: hasLossControl,
    },
  ];

  const savingsNotice = hasLossControl
    ? 'Saving up to 10% off your premium'
    : 'Save up to 10% in premium when you enroll in Manage My Risk Program';

  return (
    <>
      <Overlay showPriceDrawer={showPriceDrawer} onClick={onClose} />
      <Drawer showPriceDrawer={showPriceDrawer}>
        <Close onClick={onClose}>
          <img src={close_gray} alt="Close Cost Breakdown" />
        </Close>
        <DrawerContent>
          <TotalContainer>
            <Header>Total Breakdown</Header>
            <TotalDue>
              <div>Total Due Today:</div>
              <TotalDueAmount>
                {isMonthly ? formatCurrency(month1Owed) : formatCurrency(totalYearlyOwed)}
              </TotalDueAmount>
            </TotalDue>
          </TotalContainer>
          <PriceDetailsContainer>
            {PriceDetails.filter((e) => e.isVisible).map((detail) => (
              <SpaceBetweenDiv key={detail.label}>
                <PriceLabel>{`${detail.label}:`}</PriceLabel>
                <PriceLabel isPremium={detail.label === 'Premium'}>{formatCurrency(detail.amount)}</PriceLabel>
              </SpaceBetweenDiv>
            ))}
            {isMonthly && (
              <FuturePaymentContainer>
                <FuturePaymentLabel>
                  Future Monthly Payments:
                  {Boolean(monthlyFees) && (
                    <ToolTipIcon
                      toolTipName="Future_Monthly_Payments"
                      css={css`
                        margin-left: 4px;
                      `}
                      content={
                        <Text>
                          Future monthly payments include the monthly premium and the following fee
                          {hasLossControl && 's'}:
                          <ul>
                            <li>{formatCurrency(installmentFees as number)}/mo installment fee</li>
                            {hasLossControl && <li>{formatCurrency(lossControlFees as number)}/mo Manage My Risk</li>}
                          </ul>
                        </Text>
                      }
                    />
                  )}
                </FuturePaymentLabel>
                <PriceLabel>{formatCurrency(monthlyOwed)}</PriceLabel>
              </FuturePaymentContainer>
            )}
          </PriceDetailsContainer>
          {hasBOPorGL && (
            <SavingsContainer>
              <SavingsNotice hasLossControl={hasLossControl}>
                {hasLossControl ? <img src={checkmark_blue} alt="" /> : <img src={piggy_bank} alt="" />}
                {savingsNotice}
              </SavingsNotice>
            </SavingsContainer>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
};

const slideIn = keyframes`
from {
  transform: translateX(100%);
}
to {
  transform: translateX(0);
}
`;

const slideOut = keyframes`
from {
  transform: translateX(0);
}
to {
  transform: translateX(100%);
}
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const Overlay = styled.div<{ showPriceDrawer: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  animation: ${({ showPriceDrawer }) => (showPriceDrawer ? fadeIn : fadeOut)} 0.4s forwards;
`;

const Drawer = styled.div<{ showPriceDrawer: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.white};
  padding-left: 32px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 24px;
  z-index: 100;
  animation: ${({ showPriceDrawer }) => (showPriceDrawer ? slideIn : slideOut)} 0.4s forwards;
`;

const DrawerContent = styled.div`
  padding-right: 16px;
`;

const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SpaceBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PriceLabel = styled.span<{ isPremium?: boolean }>`
  color: ${(props) => (props.isPremium ? props.theme.colors.primary.zero : props.theme.colors.neutral.medium)};
  font-family: ${(props) =>
    props.isPremium ? props.theme.font.typeface.primary : props.theme.font.typeface.secondary};
  font-size: ${(props) => (props.isPremium ? '20px' : '16px')};
  font-weight: ${(props) => (props.isPremium ? '600' : '400')};
  line-height: ${(props) => (props.isPremium ? '26px' : '24px')};
`;

const FuturePaymentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 4px;
`;

const FuturePaymentLabel = styled.span`
  color: ${({ theme }) => theme.colors.tertiary.zero};
  font-family: ${(props) => props.theme.font.typeface.primary};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
`;

const TotalDue = styled(SpaceBetweenDiv)`
  color: ${({ theme }) => theme.colors.tertiary.zero};
  font-family: ${(props) => props.theme.font.typeface.primary};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`;

const TotalDueAmount = styled(TotalDue)`
  font-size: 30px;
  line-height: 30px;
`;

const PriceDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0px;
`;

const SavingsContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.light};
  padding-top: 16px;
`;

const SavingsNotice = styled.div<{ hasLossControl: boolean }>`
  display: flex;
  column-gap: 8px;
  width: 320px;
  padding: ${(props) => (props.hasLossControl ? '0 8px 8px 8px' : '8px')};
  color: ${(props) => (props.hasLossControl ? '#3391CC' : '#8F6224')};
  font-family: ${(props) =>
    props.hasLossControl ? props.theme.font.typeface.secondary : props.theme.font.typeface.primary};
  font-size: ${(props) => (props.hasLossControl ? '16px' : '12px')};
  font-weight: ${(props) => (props.hasLossControl ? '400' : '600')};
  letter-spacing: ${(props) => (props.hasLossControl ? '' : '0.09px')};
  line-height: ${(props) => (props.hasLossControl ? '24px' : '16px')};
  background-color: ${(props) => (props.hasLossControl ? 'transparent' : '#FFEBD0')};
  border-radius: 4px;
`;

const Close = styled.span`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

const Header = styled.p`
  color: ${({ theme }) => theme.colors.neutral.regular};
  font-family: ${(props) => props.theme.font.typeface.primary};
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-top: 24px;
`;

export default PriceDrawer;
