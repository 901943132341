import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Link from 'redux-first-router-link';
import styled from 'styled-components';
import { getAvailableEndorsements } from '../../selectors/availableEndorsements';
import { selectLoadingState } from '../../selectors';
import { selectEndorsements, selectGLLimit, selectGrossAnnualSales } from '../../selectors/application';
import AddOnQuestion from './AddOnQuestions';
import { endorsementsMap } from './addOnQuestionMap';
import { EndorsementEnum } from '../../types/enums';
import { PAGES } from '../../constants';

const AddOns = ({
  availableEndorsements,
  endorsementsState,
  isLoading,
  dispatch,
  grossAnnualSales,
  glLimit,
}: {
  availableEndorsements: AvailableEndorsementsState;
  endorsementsState?: EndorsementsInterface;
  isLoading: boolean;
  dispatch: Dispatch;
  grossAnnualSales: number;
  glLimit?: number;
}) => {
  return (
    <>
      {availableEndorsements &&
        Object.keys(availableEndorsements).map((endorsement: string) => {
          if (!endorsementsMap[endorsement]) return null;
          const { LABEL, QUESTION_COPY, subQuestions, action } = endorsementsMap[endorsement];

          const isLiquorAndHasTwoFourGlLimit =
            endorsement === EndorsementEnum.liquorLiabilityEndorsement && glLimit === 2_000_000;

          const liquorNotAvailableDescription = (
            <>
              This coverage is not available with a $2M liability limit. To add Liquor Liability Coverage, please{' '}
              {
                <StyledLink
                  to={{ type: PAGES.GENERAL_LIABILITY }}
                  id={PAGES.GENERAL_LIABILITY}
                  className="availableLink"
                  data-cy={`sidebar-to-${PAGES.GENERAL_LIABILITY}`}
                >
                  go back
                </StyledLink>
              }{' '}
              and change your limits.
            </>
          );

          const description = isLiquorAndHasTwoFourGlLimit ? liquorNotAvailableDescription : QUESTION_COPY;

          return (
            <AddOnQuestion
              endorsement={endorsement}
              label={LABEL}
              description={description}
              subQuestions={subQuestions}
              value={endorsementsState?.[endorsement]}
              action={(value: any) => dispatch(action(value))}
              key={endorsement}
              isLoading={isLoading}
              validationData={{ grossAnnualSales }}
              disabled={isLiquorAndHasTwoFourGlLimit}
            />
          );
        })}
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  availableEndorsements: getAvailableEndorsements(state),
  endorsementsState: selectEndorsements(state),
  isLoading: selectLoadingState(state),
  grossAnnualSales: selectGrossAnnualSales(state) || Infinity,
  glLimit: selectGLLimit(state),
});

const StyledLink = styled(Link)`
  color: #0d0d46 !important;
`;

export default connect(mapStateToProps)(AddOns);
